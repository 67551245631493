import React from 'react';

export default ({ data }) => {
  return data.contents.map((item) => (
    <div key={item.type} class="mobile-submenu-col">
      {item.type === 'big-item' ? (
        <>
          <div class="mobile-submenu-title"></div>
          <div class="mobile-submenu-items  ">
            {item.items.map((element) => (
              <div key={element.text} class="big-item">
                <a href={element.link}>{element.text}</a>
              </div>
            ))}
          </div>
        </>
      ) : (
        <>
          <div class="mobile-submenu-title">{item.title}</div>
          <div class="mobile-submenu-items  ">
            <div class="single-item ">
              {item.items.map((element) => (
                <a key={element.text} href={element.link}>
                  {element.text}
                </a>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  ));
};
